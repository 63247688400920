$colors: (
    red: #ff4949,
    white: #ffffff,
    gray: #fafafa,
    // Green and Black for mic and video icons
    green: #00d786,
    black: #0c0c0c,
    // Blue for buttons etc
    blue: #0a2136,
    presentation-blue: #0066ff,
    teal: #00fff7,
    blue-on-black: #0a2136,
    lighter-blue: #0ebec7,
    lighter-blue-on-black: #0ebec7,
    dark-gray: #4a4a4a,
    gray-border: #4a4a4a,
    primary-color: black,
    secondary-color: #909090,
    background-primary: white,
    background-hover: #f2f2f2,
    media-background: #19181a,
    overlay-background: #000,
    overlay-background--hover: #4f4f4f,
    overlay-foreground: #fff,
    toolbar-button-foreground: #cdcdcd,
    conference-button: #919191,
    mute-red-on-video: #ff7273,
    active-green-on-video: #00d786,
    mute-red: #ff7273,
    lock-orange: #f38b3c,
    list-header: #292929,
    list-header-border: #aeadad,
    tooltip-background: #c5c3c3,
    tooltip-border: #c5c3c3,
    empty-recent-text: #808080,
    live-caption-history: #838383,
    event-timestamp-text: #565656
);
$high-contrast: (
    white: #ffffff,
    red: #ff4949,
    toolbar-button-foreground: #ffffff,
    blue-on-black: #afd4ff,
    background-primary: white,
    overlay-foreground: #fff,
    background-hover: #f2f2f2,
    mute-red-on-video: #e68b8d,
    active-green-on-video: #8be6b4,
    tooltip-background: #fff,
    tooltip-border: #000,
    lighter-blue-on-black: #0ebec7
);
$primary-font-size: 16px;
$secondary-font-size: 14px;
$secondary-font-weight: 300;
$layout-breakpoint-mobile: 500px;
$layout-breakpoint-width: 768px;
$layout-breakpoint-tablet-width: 1200px;
$layout-aspect-ratio: '4/5';
$selfview-aspect-ratio: 56.25%;
$min-width: 320px;
$max-width: 1920px;
$min-font: 16px;
$max-font: 24px;
$outline-width: 0;
@import 'tools/themes';
@mixin word-wrap() {
    overflow-wrap: break-word;
    word-wrap: break-word;
    -ms-word-break: break-all;
    word-break: break-word;
}
@mixin --truncate-line() {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
@mixin --shadow() {
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.03);
}
@mixin --arrow($background-color) {
    content: '';
    position: absolute;
    bottom: 100%;
    border: 6px solid transparent;
    border-bottom-color: $background-color;
}
@mixin --pex-fullscreen() {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}
@mixin user-select($value) {
    -moz-user-select: $value;
    -webkit-user-select: $value;
    -ms-user-select: $value;
    user-select: $value;
}
/* Object Fit
    Fit objects horizontally and vertically
*/
@mixin --pex-object-fit() {
    text-align: center;
    white-space: nowrap;

    &:before {
        content: '';
        display: inline-block;
        height: 100%;
        vertical-align: middle;
        margin-right: -0.25em;
    }
}
@mixin --pex-button-reset() {
    background: transparent;
    cursor: pointer;
    border: none;
    @include user-select(none);
}
@mixin center() {
    position: relative;
    left: 50%;
    transform: translateX(-50%);
}
@mixin placeholder-color($color) {
    ::-webkit-input-placeholder {
        /* WebKit, Blink, Edge */
        color: $color;
    }

    :-moz-placeholder {
        /* Mozilla Firefox 4 to 18 */
        color: $color;
        opacity: 1;
    }

    ::-moz-placeholder {
        /* Mozilla Firefox 19+ */
        color: $color;
        opacity: 1;
    }

    :-ms-input-placeholder {
        /* Internet Explorer 10-11 */
        color: $color;
    }
}

@import 'tools/fonts';
@import 'components/list';
